import { StateDistrict } from "./states";

export interface CampaignInfo {
	src: string | null;
	description: string;
}

export type CampaignLookup = {
	[key in StateDistrict]?: CampaignInfo;
};

export const campaignLookup: CampaignLookup = {
	NY: {
		src: `https://act.shipmyspirits.org/campaign/38719/?embedded=true&`,
		description: `New York’s Spirits Consumers Deserve Convenience`,
	},
	VT: {
		src: `https://act.shipmyspirits.org/campaign/39977/?embedded=true&`,
		description: `Vermont’s Spirits Consumers Deserve Convenience`,
	},
	DE: {
		src: `https://act.shipmyspirits.org/campaign/40841/?embedded=true&`,
		description: `Delaware’s Spirits Consumers Deserve Convenience`,
	},
};
