import React, { useState, useEffect } from "react";
import tw, { css } from "twin.macro";

import {
	StateDistrict,
	stateNames,
} from "../../util/data/states";
import CampaignBox from "../campaigns/campaign-box";

import statesByZip from "../../util/data/statesByZip.json";

import {
	campaignLookup,
	CampaignInfo,
} from "../../util/data/campaigns";

const lookupStateByZip = (
	zip: string
): StateDistrict | null => {
	const lookupObject: Record<string, string> = statesByZip;
	const lookedUpState = lookupObject?.[zip];

	// check that lookUpState is an expected state name abbreviation
	if (Object.keys(stateNames).includes(lookedUpState)) {
		return lookedUpState as StateDistrict;
	}

	return null;
};

const CampaignSearch = () => {
	// TODO: Refactor with useReducer

	const [searchClicked, setSearchClicked] =
		useState<boolean>(false);

	const [currentState, setCurrentState] =
		useState<StateDistrict | null>(null);

	const [currentCampaignData, setCurrentCampaignData] =
		useState<CampaignInfo | null>(null);

	const [zip, setZip] = useState<string | null>(null);

	const [loadingZipData, setLoadingZipData] =
		useState<boolean>(false);

	useEffect(() => {
		if (currentState !== null) {
			setCurrentCampaignData(
				campaignLookup[currentState] ?? null
			);
		}
	}, [currentState]);

	return (
		<>
			<div tw="mb-6 flex flex-col lg:(flex-row justify-center items-center py-4)">
				<h1 tw="text-base font-bold mb-2 lg:(mb-0 mr-4)">
					Show campaigns in your area:
				</h1>
				<form
					tw="grid grid-template-columns[55fr 45fr] gap-x-2"
					onSubmit={(e) => {
						e.preventDefault();
						setLoadingZipData(true);
						if (zip) {
							const stateByZip = lookupStateByZip(zip);
							setCurrentState(stateByZip);
						}

						setTimeout(() => {
							setLoadingZipData(false);
						}, 1000);

						setSearchClicked(true);
					}}
				>
					<input
						required
						onChange={(e) => {
							e.preventDefault();
							// use regex to filter out non digits
							const digitString = e.target.value.replace(
								/\D/g,
								``
							);
							setZip(digitString);
						}}
						value={zip ?? ``}
						css={[
							tw`w-full`,
							tw`bg-light-beige`,
							tw`border-width[1px] border-bitters rounded-md`,
							tw`p-2`,
							css`
								::placeholder {
									color: #310f0c;
								}
							`,
						]}
						placeholder="Zip Code"
						maxLength={5}
						minLength={5}
					/>
					<button
						css={[
							tw`w-full`,
							tw`bg-bourbon text-white rounded-md font-medium uppercase`,
							tw`py-2`,
						]}
						type="submit"
					>
						Search
					</button>
				</form>
			</div>
			{/* // campaign list */}
			<div
				css={[
					tw`pb-4 pt-2 border-b-2 border-bottom-color[#dfdac9]`,
					tw`lg:(pb-12 pt-0)`,
					// style for both med screen widths and ipad
					css`
						@media only screen and (min-width: 768px) {
							display: flex;
							flex-wrap: wrap;
						}
					`,
					css`
						@media only screen and (min-width: 768px) and (-webkit-min-device-pixel-ratio: 2) {
							display: flex;
							flex-wrap: wrap;
						}
					`,
				]}
			>
				{searchClicked &&
					(loadingZipData ? (
						<div tw="flex justify-center w-full">
							<p>Loading...</p>
						</div>
					) : currentCampaignData ? (
						<>
							<CampaignBox
								state={currentState}
								campaignInfo={currentCampaignData}
							/>
							{` `}
						</>
					) : (
						<CampaignBox state={currentState} />
					))}
			</div>
		</>
	);
};

export default CampaignSearch;
