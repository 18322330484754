/* eslint-disable react/no-unknown-property */
import tw, { css } from "twin.macro";
import { Link } from "gatsby";
import {
	StateDistrict,
	stateNames,
} from "../../util/data/states";

import { CampaignInfo } from "../../util/data/campaigns";

const CampaignBox = ({
	state,
	campaignInfo,
}: {
	state?: StateDistrict | null;
	campaignInfo?: CampaignInfo | null;
}) => {
	return (
		<div
			css={[
				tw`bg-oat w-full`,
				// style for both med screen widths and ipad
			]}
		>
			<h3 tw="bg-bitters text-light-beige font-sticker text-center text-3xl p-2">
				{(state !== null &&
					state !== undefined &&
					stateNames?.[state]) ||
					`USA`}
			</h3>
			<div tw="flex flex-col items-center justify-center font-display px-8 py-8 lg:min-height[186px]">
				<p tw="mb-4">
					{campaignInfo?.description ??
						`Help your favorite distillers AND promote consumer convenience!`}
				</p>
				<Link
					to={
						state
							? `/take-action/${state}`
							: `/take-action/USA`
					}
					css={[
						tw`bg-bourbon text-white rounded-md font-medium uppercase`,
						tw`pt-3 pb-1.5 px-3 width[216px] text-center`,
					]}
				>
					Take Action
				</Link>
			</div>
		</div>
	);
};

export default CampaignBox;
