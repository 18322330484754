import React from "react";
import { Link } from "gatsby";
import tw, { css } from "twin.macro";
import { StaticImage } from "gatsby-plugin-image";

import DoubleBorderDiv from "../util/double-border-div";
import IssueList from "./issue-list";
import tealBgImage from "../../assets/images/sms-seal.png";
import mobileTealBgImage from "../../assets/images/sms-seal-mobile.png";
import OrangeArrow from "../../assets/svgs/orange-arrow.inline.svg";
import CurvyGrapic from "../../assets/svgs/landing-curvy-graphic.inline.svg";
import CurvyGrapicMobile from "../../assets/svgs/landing-curvy-graphic-mobile.inline.svg";

const LandingIssueBreakdown = () => {
	return (
		<div tw="font-display">
			<div
				css={[
					tw`bg-mist p-6 z-0 relative overflow-x-hidden w-screen lg:(p-12 flex flex-col items-center)`,
					css`
						background-image: url(${mobileTealBgImage});
						background-size: 40%;
						background-repeat: no-repeat;
						background-blend-mode: multiply;
						background-position: 0% 75%;
						@media (min-width: 1024px) {
							background-image: url(${tealBgImage});
							background-position: 47% 95%;
							background-size: 15%;
							background-blend-mode: multiply;
						}
					`,
				]}
			>
				<div tw="max-w-landing-page">
					<DoubleBorderDiv>
						<div tw="text-bitters lg:(px-16 pt-16 pb-12 grid grid-cols-2 grid-template-rows[2fr 3fr])">
							<h1 tw="uppercase font-sticker text-3xl lg:(row-start-1 col-start-1 row-end-2 col-end-2 text-4xl mr-8)">
								Allowing distillers to ship directly to
								consumers helps everyone.
							</h1>
							<div tw="lg:(row-start-1 col-start-2 row-end-3 col-end-3 px-10)">
								<IssueList />
							</div>
							<div tw="lg:(pt-4)">
								<Link
									to="/issue-summary"
									tw="text-orange w-full max-width[300px] uppercase font-medium flex justify-between lg:(row-start-2 col-start-1 row-end-3 col-end-2)"
								>
									Learn more about the issue <OrangeArrow />
								</Link>
							</div>
						</div>
					</DoubleBorderDiv>
				</div>
				<div
					css={[
						tw`mt-8  lg:(w-full max-w-landing-page grid grid-template-rows[auto auto] grid-cols-2)`,
						tw`lg:(-translate-y-48 -mb-48)`,
					]}
				>
					<div tw="mb-8 -ml-6 lg:(m-0 px-20)">
						<StaticImage
							tw="w-4/5 max-width[400px] lg:(w-auto)"
							src="../../assets/images/landing-issue-1.png"
							alt="a woman receiving a package from a delivery person"
						/>
					</div>
					<div tw="mb-8 -mr-6 flex justify-end lg:(m-0 px-10 block row-start-2 col-start-2)">
						<StaticImage
							tw="w-5/6 max-width[575px] lg:(transform[translateY(-20%)] width[200%])"
							src="../../assets/images/landing-issue-2.png"
							alt="a group of friends having a candle lit dinner"
						/>
					</div>
					<div tw="mb-8 -ml-6 lg:(m-0 row-start-2 flex items-center)">
						<StaticImage
							tw="w-4/5 max-width[365px] lg:(w-auto -translate-x-20)"
							src="../../assets/images/landing-issue-3.png"
							alt="a person's hands holding grains over a barrel"
						/>
					</div>
				</div>
			</div>
			<div tw="bg-bourbon px-6 pb-12 flex justify-center z-50 relative">
				<div tw="margin-top[-5.5rem] w-full flex justify-center max-w-landing-page">
					<DoubleBorderDiv>
						<div tw="flex flex-col items-center pt-2">
							<h3 tw="font-sticker text-orange uppercase mb-1">
								From the distiller to your doorbell
							</h3>
							<h1
								css={[
									tw`text-3xl text-bitters border-2 pb-3 w-1/2 flex justify-center`,
									tw`border-r-transparent border-l-transparent border-t-transparent border-b-orange border-dashed`,
									tw`font-alternate lg:text-7xl`,
								]}
							>
								How&nbsp;does&nbsp;it&nbsp;work?
							</h1>
							<div tw="-mx-4 py-8 w-full max-width[600px] flex justify-center lg:(pt-20)">
								<CurvyGrapic tw="hidden md:block" />
								<CurvyGrapicMobile tw="md:hidden" />
							</div>
							<div tw="w-full flex justify-end">
								<h3 tw="text-xs lg:text-base">
									* in states where permitted
								</h3>
							</div>
						</div>
					</DoubleBorderDiv>
				</div>
			</div>
		</div>
	);
};

export default LandingIssueBreakdown;
