import tw, { css } from "twin.macro";
import { useStaticQuery, graphql } from "gatsby";

const Row = ({
	number,
	copy,
}: {
	number: number;
	copy: string;
}) => (
	<div tw="grid grid-template-columns[30px 1fr] pb-7 lg:(pb-4)">
		<div
			css={[
				tw`font-sticker text-light-beige  flex justify-center items-center`,
			]}
		>
			<span tw="bg-oat rounded-full transform[translateY(-1px)] width[30px] height[30px] text-center pt-0.5">
				{number.toString()}
			</span>
		</div>
		<div tw="ml-4 -mt-3 font-medium flex items-center lg:(mt-0)">
			<span tw="translate-y-0.5">{copy}</span>
		</div>
	</div>
);

const IssueList = () => {
	const data = useStaticQuery(graphql`
		query HomePageBulletedList {
			sanityHome {
				heroCopy
				heroDownButtonText
				bulletedPro
			}
		}
	`);

	return (
		<div tw="px-2 pb-6 pt-9 lg:(py-0) font-display">
			{data?.sanityHome?.bulletedPro.map(
				(item: string, i: number) => {
					return <Row key={i} number={i + 1} copy={item} />;
				}
			)}
			{/* <Row
				number={1}
				copy="Increases consumer convenience and choice"
			/>
			<Row
				number={2}
				copy="Provides a positive boost for tourism and the hospitality industry"
			/>
			<Row
				number={3}
				copy="Treats wineries and distilleries the same"
			/>
			<Row
				number={4}
				copy="Builds small businesses and enhances distribution "
			/>
			<Row
				number={5}
				copy="Maintains important safety measures to prevent underage access"
			/> */}
		</div>
	);
};

export default IssueList;
