/* eslint-disable prettier/prettier */
import { useState, ChangeEvent } from "react";
import { navigate } from "gatsby";
import StateDropdown from "../forms/state-dropdown";
import { StateDistrict } from "../../util/data/states";

const MapPreviewNav = () => {
	const [selected, setSelected] = useState<StateDistrict | null>(null);

	const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
		setSelected(e.target.value as StateDistrict);
	};

	const handleSubmit = () => {
		if (selected) {
			navigate(`/state-facts?state=${selected}`);
		}
		// if (!currentState) {
		// 	if (window.innerWidth < 1024) {
		// 		scrollTo(`#interactive-map`);
		// 	} else {
		// 		scrollTo(`#state-data`);
		// 	}
		// }
	};


	return (
		<StateDropdown
			onSubmitHandler={handleSubmit}
			handleChange={(e: ChangeEvent<HTMLSelectElement>) => handleChange(e)}
		/>
	);
};

export default MapPreviewNav;
